<template>
  <v-row justify="center" class="my-8">
    <v-col cols="12" xs="12" sm="8" align="center" justify="center" class="mainTextColor--text subtitle-1" style="max-width: 800px;">
      <div class="mb-4" v-for="({ href, icon, title, subTitle }, i) of method" :key="i">
        <v-btn text x-large block :href="href" class="mainTextColor--text">
          <v-icon large class="mr-3">{{ icon }}</v-icon>
          <div>{{ title }}</div>
          <div v-if="subTitle">{{subTitle}}</div>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ContactDetails',
  data: () => ({
    method: [
      {
        href: 'mailto:register@thetitlegirl.com',
        icon: 'mdi-email',
        title: 'register@thetitlegirl.com'
      },
      {
        href: 'tel:+12149976005',
        icon: 'mdi-phone',
        title: '(214) 997-6005'
      },
      {
        href: 'https://goo.gl/maps/f9PnMo3xPJXWMrpz5',
        icon: 'mdi-map-marker',
        title: '16775 Addison Road, Suite 400',
        // subTitle: 'Addison, TX 75001'
      }
    ]
  })
};
</script>
